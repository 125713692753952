<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Templates for approval
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :quickSearch="filters.search"
            :url="url"
            ref="grid"
        ></data-table>
    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
export default {
    name: 'SupplierTemplatesForApprovalOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Template name': {
                        field: 'productConfigurationName',
                        link: (value, data) => {
                            return {
                                name: 'SupplierProductConfigurationPropositions',
                                params: {
                                    ...this.$route.params,
                                    productConfigurationId: data.productConfigurationId,
                                },
                            }
                        },
                    },
                    'Products using template': {
                        field: 'productConfigurationNames',
                    },
                    'Status': {
                        field: 'totalOpen',
                        statusChip: true,
                        valueGetter: ({ data }) => data.totalOpen === 0 ? 'APPROVED' : 'APPROVAL_NEEDED',
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                    'Last Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                },
                quickSearchColumns: ['productConfigurationName'],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES_FOR_APPROVAL.LIST.replace('{supplierId}', this.supplierId)
        },
    },
}
</script>
